import MainRoutes from "./mainRoutes";
export default {
    path: 'configuration',
    component: () => import(/* webpackChunkName: "configurationMain" */ '@/modules/configuration/ConfigurationMain.vue'),
    meta: {
        requiresAuth: true,
        theme: "blue",
        label: "Configuration",
        include: true,
        minimumPermission: "InternalAdmin",
    },
    children: [
        {
            path: "",
            component: () => import(/* webpackChunkName: "configurationSearch" */ '@/modules/configuration/ConfigurationSearch.vue'),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "",
                itemTypeName: "Configuration",
                minimumPermission: "InternalAdmin",
                include: false
            }
        },
        {
            path: "main",
            component: () => import(/* webpackChunkName: "configurationSearch" */ '@/modules/configuration/TopicMain.vue'),
            meta: {
                requiresAuth: true,
                theme: "blue",
                label: "Main",
                minimumPermission: "InternalAdmin",
                include: true
            },
            children: [
                ...MainRoutes
            ]
        }
    ]
}
import { Topics } from "./topics";
import { Categories } from "./categories";
export default [
    {
        path: "settings",
        name: "titlecompany:scsystemconfigurationsetup",
        component: () => import(/* webpackChunkName: "configurationSystemSettings" */ '@/modules/configuration/systemConfigurationMaintenance/ConfigurationSystemSettings.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Settings",
            itemKey: "id",
            itemTypeName: "Settings",
            itemTypeDescription: "",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
            scrollLayout: true,
            showSaveInBreadcrumb: false,
        }
    },
    {
        path: "titleCompanySetup",
        name: "titlecompany:sctitlecompanysetup",
        component: () => import(/* webpackChunkName: "companyEdit" */ '@/modules/configuration/titleCompanySetup/CompanyEdit.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Title Company",
            showSaveInBreadcrumb: false,
            //itemKey: "id",
            itemTypeName: "Title Company",
            itemTypeDescription: "Create and edit title company details",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            scrollLayout: true
        }
    },
    {
        path: "wiringInstructionsMaintenance",
        name: "titlecompany:scwiringinstructions",
        component: () => import(/* webpackChunkName: "escrowBankList" */ '@/modules/configuration/wiringInstructionsMaintenance/EscrowBankList.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Wiring Instructions",
            itemKey: "id",
            itemTypeName: "Wiring Instructions",
            itemTypeDescription: "Edit Wiring Instructions",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
        }
    },
    {
        path: "milestoneMaintenance",
        name: "titlecompany:scmilestone",
        component: () => import(/* webpackChunkName: "configurationMilestones" */ '@/modules/configuration/milestoneMaintenance/ConfigurationMilestones.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Milestones",
            /*itemKey: "id",*/
            itemTypeName: "Milestones",
            itemTypeDescription: "Create and Edit Milestones for Transaction Type",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            scrollLayout: true
        }
    },
    {
        path: "userAccountMaintenance",
        name: "titlecompany:scuseraccount",
        component: () => import( /* webpackChunkName: "userAccountMaintenance" */ "@/modules/configuration/userAccountMaintenance/UserAccountMaintenanceMain.vue"),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Users",
            itemKey: "id",
            itemTypeName: "Users",
            itemTypeDescription: "Create and edit Users",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "InternalAdmin",
            scrollLayout: true
        }
    },
    {
        path: "roleMaintenance",
        name: "titlecompany:sccontactroleentity",
        component: () => import(/* webpackChunkName: "configurationContactRoleEntities" */ '@/modules/configuration/contactRoleEntityMaintenance/ConfigurationContactRoleEntities.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Buyer and Seller Party Roles",
            itemKey: "id",
            itemTypeName: "Role",
            itemTypeDescription: "Edit Buyer and Seller Party Roles",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
            scrollLayout: true
        }
    },
    {
        path: "communicationMaintenance",
        name: "titlecompany:sccommunicationtemplate",
        component: () => import(/* webpackChunkName: "communicationTemplateGrid" */ '@/modules/configuration/communicationTemplateMaintenance/CommunicationTemplateGrid.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Notifications",
            itemKey: "id",
            itemTypeName: "Notifications",
            itemTypeDescription: "Edit Notification Templates",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
            scrollLayout: false
        }
    },
    {
        path: "staffRoleMaintenance",
        name: "titlecompany:scstaffroleentity",
        component: () => import(/* webpackChunkName: "configurationStaffRoleEntities" */ '@/modules/configuration/staffRoleEntityMaintenance/ConfigurationStaffRoleEntities.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Staff Roles",
            itemKey: "id",
            itemTypeName: "Role",
            itemTypeDescription: "Edit Staff Roles",
            topic: Topics.Main,
            category: Categories.MyTeam,
            minimumPermission: "SiteAdmin",
            showAdd: false,
            scrollLayout: true
        }
    },
    {
        path: "predefinedDocumentSharingMaintenance",
        name: "titlecompany:scdocumentTypeRole",
        component: () => import(/* webpackChunkName: "configurationStaffRoleEntities" */ '@/modules/configuration/documentTypeRoleMaintenance/ConfigurationDocumentTypeRoleMapping.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Predefined Document Sharing",
            itemKey: "id",
            itemTypeName: "DocumentTypeRole",
            itemTypeDescription: "Create and edit automated document sharing",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
            scrollLayout: true
        }
    },
    {
        path: "titlecoInstructionsMaintenance",
        name: "titlecompany:sctitlcoinstructions",
        component: () => import('@/modules/configuration/titlecoInstructionsMaintenance/InstructionsEdit.vue'),
        meta: {
            requiresAuth: true,
            theme: "blue",
            label: "Proceeds",
            itemKey: "id",
            itemTypeName: "Proceeds",
            itemTypeDescription: "",
            topic: Topics.Main,
            category: Categories.Site,
            minimumPermission: "SiteAdmin",
            showAdd: false,
        }
    },
];